import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderReports,
  addMoreOrderProcessReportRecords,
} from "../../../../actions/orders_actions";
import { getOrderReports as orderReportsGetApiCall } from "../../../../functions/api/orders";
import { wrap } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../../LoaderNew/Loader";
import { ORDER_TASKS_REPORT } from "../../../../constants/translations/order-tasks-report";
import { getClientDateForPopup } from "../../../../functions/api/popup";
import {
  setOrderDataForPopup,
  showMainPopup,
  setOrderIdForPopup,
} from "../../../../actions/mainPopup_actions";

export default function ReportsTable({ ganttTemplateType, orderId, }) {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(200);
  const [loading, setLoading] = useState(false);
  const [hasMoreRecords, setHasMoreRecords] = useState(true);
  const data = useSelector((state) => state.orders.orderProcessesReport.report);
  let colLength = data.result.cols.length;

  const dispatch = useDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {},
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      direction: "rtl",
      // border: "1px solid balck",
    },
    headCellVerticalBox: {
      padding: "0px",
      margin: "0px",
      position: "relative",
      width: "30px",
      height: "20%",
    },
    headCellHorizontalBox: {
      padding: "0px",
      margin: "0px",
      position: "relative",
      width: "150px",
      height: "20%",
    },
    headCellName: {
      display: "flex",
      width: "200px",
      position: "absolute",
      top: "calc(150px-25%)",
      left: "-85px",
      padding: "0px",
      margin: "0px",
      whiteSpace: 'nowrap',
      overflow:'hidden',
      textOverflow: 'ellipsis'
    },
    container: {
      maxHeight: "calc(100vh - 160px)",
      direction: "ltr",
      overflow: "auto",
    },
    pagination: {
      direction: "ltr",
    },
   
  }));
  const classes = useStyles();
  const overloadColor = "";

  const StyledHeadCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#243748",
      color: theme.palette.common.white,
      left: "unset",
      padding: "0px",
      margin: "0px",
      height: "200px"
    },
    body: {
      fontSize: 13,
      fontFamily: "Rubik",
    }
  }))(TableCell);

  const StyledTableCell = withStyles((theme) => ({
     tableCell__sidebar: {
      background: 'white',
      "&:hover": {
         background: "blue"
      }
    }
  }))(TableCell);

  const scrollMoreReports = async () => {
    const page = pageNumber + 1;
    // setLoading(true);
    const response = await orderReportsGetApiCall({
      type: ganttTemplateType,
      page,
      limit: pageLimit,
    });
    // setLoading(false);
    if (!response.ok) {
      setHasMoreRecords(false);
      return;
    }

    if (
      response.ok &&
      response.result.rows &&
      response.result.rows.length === 0
    ) {
      setHasMoreRecords(false);
      return;
    }
    setPageNumber(page);
    dispatch(addMoreOrderProcessReportRecords(response, ganttTemplateType));
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getOrderReports(1, pageLimit, ganttTemplateType, orderId, setLoading));
    setHasMoreRecords(true);
    setPageNumber(1);
  }, [dispatch, ganttTemplateType, orderId, pageLimit]);

  //for pop up!
  const openEditClientPopup = async (order_id) => {
    let order_data_for_popup = await getClientDateForPopup(order_id);
    if (order_data_for_popup.ok) {
      dispatch(setOrderDataForPopup(order_data_for_popup.result));

      dispatch(setOrderIdForPopup(order_id));
      dispatch(showMainPopup(true));
    } else {
     
    }
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.container}>
        {/* <InfiniteScroll
          pageStart={pageNumber}
          loadMore={scrollMoreReports}
          hasMore={hasMoreRecords}
          useWindow={false}
          threshold={300}
          initialLoad={false}
          initialScrollTop={0}
        > */}
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
          {loading ?
            <Loader />
            :
            <React.Fragment><TableHead
              style={{
                height: "150px",
              }}
            >
              <TableRow>
                <StyledHeadCell
                  style={{
                    position: "sticky",
                    right: "0px",
                    zIndex: 99,
                    background: "#fafafa",
                    color: "#243748",
                    textAlign: "right",
                    margin: "0px",
                    padding: "15px",
                  }}
                >
                  <div style={{ fontWeight: '700', fontSize: '20px' }}>{ORDER_TASKS_REPORT.DETAILS}</div>
                </StyledHeadCell>
                {data &&
                  data.result.cols.map((headCell, index) => {
                    if (index === 0) {
                      return (
                        <StyledHeadCell
                          component="th"
                          scope="row"
                          key={index}
                          align={headCell.align}
                          style={{
                            padding: "0px 0px !important",
                            margin: "0px !important",
                          }}
                        >
                          <div
                            className={colLength >= 7 ? classes.headCellVerticalBox : classes.headCellHorizontalBox}
                          >
                            <div
                              className={classes.headCellName}
                              style={{
                                transform: `${colLength >= 7
                                    ? "rotate(90deg)"
                                    : "rotate(0deg)"
                                  }`,
                              }}
                            >
                              {" "}
                              {headCell}
                            </div>
                          </div>
                        </StyledHeadCell>
                      );
                    }
                    return (
                      <StyledHeadCell
                        component="th"
                        scope="row"
                        key={index}
                        align={headCell.align}
                        style={{
                          padding: "0px 0px !important",
                          margin: "0px !important",
                        }}
                      >
                        <div
                          className={
                            colLength >= 7
                              ? classes.headCellVerticalBox
                              : classes.headCellHorizontalBox
                          }
                        >
                          <div
                            className={classes.headCellName}
                            style={{
                              transform: `${colLength >= 7
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)"
                                }`,
                            }}
                          >
                            {" "}
                            {headCell}
                          </div>
                        </div>
                      </StyledHeadCell>
                    );
                  })}
                {/* <StyledHeadCell>{ORDER_TASKS_REPORT.ORDER_NAME}</StyledHeadCell> */}
              </TableRow>
            </TableHead>
              {
                <TableBody>
                  {data &&
                    data.result.rows.map((element) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={element.order_id}
                        >
                          <StyledTableCell
                            onClick={() =>
                              openEditClientPopup(element?.orderDetails?.id)
                            }
                            className="tableCell__sidebar"
                            style={{
                              position: "sticky",
                              right: "0px",
                              zIndex: 1,
                              // background: "white",
                              width: "180px",
                              borderLeft: "1px solid black",
                              margin: "0px",
                              textAlign: 'right',
                              padding: '2px',
                              cursor: 'pointer',

                            }}
                          >


                            <Tooltip title={
                              <>
                                <Typography>{element.order_id}</Typography>
                                <Typography>{element?.orderDetails?.clientName}</Typography>
                                <Typography>{element?.orderDetails?.city}</Typography>
                              </>
                            } arrow>
                              <div>
                                <p style={{
                                  color:
                                    overloadColor ||
                                    element?.orderDetails?.approvedDone || 'blue',
                                }}> &nbsp; {element.order_id} </p>
                                <p
                                  style={{
                                    color:
                                      overloadColor ||
                                      element?.orderDetails?.approvedDone,
                                    width: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: "hidden",
                                    textOverflow: 'ellipsis'

                                  }}
                                >
                                  {" "}
                                  &nbsp; &nbsp;
                                  {element?.orderDetails?.clientName}
                                  {element?.orderDetails?.city ? (
                                    <span
                                      style={{
                                        color:
                                          overloadColor ||
                                          element?.orderDetails?.approvedDone,
                                      }}
                                    >
                                      {" "}
                                      {element?.orderDetails?.city}
                                    </span>
                                  ) : (
                                    <span></span>
                                  )}
                                </p>
                              </div>
                            </Tooltip>

                          </StyledTableCell>
                          {/* </TableCell> */}
                          {data &&
                            data.result.cols.map((col, index) => {
                              const color =
                                col in element.data
                                  ? element.data[col].color
                                  : "#ffffff";
                              return (
                                <TableCell
                                  component="th"
                                  scope="row"
                                  key={col.id}
                                  style={{
                                    borderLeft: "1px solid #ececec",
                                    margin: "0px",
                                    padding: "0px 2px",
                                  }}
                                >
                                  <p
                                    style={{
                                      background: color,
                                      display: "flex",
                                      height: "40px",
                                      padding: "0px 2px",
                                      margin: "0px",
                                      width: "100%",
                                    }}
                                  >
                                    &nbsp;
                                  </p>
                                </TableCell>
                              );
                            })}
                          {/* <TableCell>{element.order_id}</TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              }
            </React.Fragment>
          }
          </Table>
        {/* </InfiniteScroll> */}
      </TableContainer>
    </React.Fragment>
  );
}
